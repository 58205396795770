import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('touchList')))])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.showMarketDialog
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.addTouch')) + "\n    ")])], 1), _vm._v(" "), _c('ContentArea', [_c('TouchItems', {
    attrs: {
      "filters": _vm.filterOptions,
      "options": _vm.tableOptions,
      "type": _vm.type
    },
    on: {
      "update:filters": function updateFilters($event) {
        _vm.filterOptions = $event;
      },
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      },
      "update:type": function updateType($event) {
        _vm.type = $event;
      },
      "click": _vm.onClickItem
    }
  })], 1), _vm._v(" "), _c('ProductSelectDialog', {
    ref: "dialog"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };