/* unplugin-vue-components disabled */import "core-js/modules/es.function.name.js";
import TouchItems from './widgets/TouchItems.vue';
import ProductSelectDialog from './widgets/ProductSelectDialog.vue';
export default {
  components: {
    TouchItems: TouchItems,
    ProductSelectDialog: ProductSelectDialog
  },
  layout: 'dashboard',
  head: function head() {
    return {
      title: this.$t('touchList')
    };
  },
  computed: {
    type: {
      get: function get() {
        return useSettingsStore().getTouchesDisplayType(this.$route.name);
      },
      set: function set(value) {
        useSettingsStore().updateTouchesDisplayType(this.$route.name, value);
      }
    },
    tableOptions: {
      get: function get() {
        return useHistoryStore().getTableOptions();
      },
      set: function set(value) {
        return useHistoryStore().saveTableOptions(value);
      }
    },
    filterOptions: {
      get: function get() {
        return useHistoryStore().getFilterOptions({
          onlyAvailable: true,
          createdBy: [],
          keywordText: '',
          createdAfterDate: '',
          createdBeforeDate: ''
        });
      },
      set: function set(value) {
        return useHistoryStore().saveFilterOptions(value);
      }
    }
  },
  methods: {
    onClickItem: function onClickItem(item, event) {
      this.$pushOrNewTab("/touch/".concat(item.id), event);
    },
    showMarketDialog: function showMarketDialog() {
      this.$refs.dialog.show();
    }
  }
};