import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "overlay": false,
      "fullscreen": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c(VCard, [_c(VToolbar, {
    attrs: {
      "color": "primary"
    }
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-icon-on-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_c('mdi-close', {
    staticClass: "tw-size-6"
  })], 1), _vm._v(" "), _c(VToolbarTitle, [_vm._v("商品選択")]), _vm._v(" "), _c(VSpacer)], 1), _vm._v(" "), _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c('ProductItems', {
    attrs: {
      "products": _vm.products,
      "loading": _vm.loading
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };