/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ProductSelectDialog.vue?vue&type=template&id=9638528e"
import script from "./ProductSelectDialog.vue?vue&type=script&lang=js"
export * from "./ProductSelectDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports